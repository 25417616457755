* {
  /* prevent browsers from automatically swapping out text with alternative glyphs */
  font-variant-ligatures: none;
}

@font-face {
  font-family: 'Ginto Normal';
  src: url('/_ssr/fonts/GintoNormal/ABCGintoNormal-Regular.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNormal/ABCGintoNormal-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Normal';
  src: url('/_ssr/fonts/GintoNormal/ABCGintoNormal-Medium.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNormal/ABCGintoNormal-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Normal';
  src: url('/_ssr/fonts/GintoNormal/ABCGintoNormal-Bold.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNormal/ABCGintoNormal-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Normal';
  src: url('/_ssr/fonts/GintoNormal/ABCGintoNormal-RegularItalic.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNormal/ABCGintoNormal-RegularItalic.woff') format('woff');
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Normal';
  src: url('/_ssr/fonts/GintoNormal/ABCGintoNormal-MediumItalic.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNormal/ABCGintoNormal-MediumItalic.woff') format('woff');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Normal';
  src: url('/_ssr/fonts/GintoNormal/ABCGintoNormal-BoldItalic.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNormal/ABCGintoNormal-BoldItalic.woff') format('woff');
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

/* Ginto Nord */

@font-face {
  font-family: 'Ginto Nord';
  src: url('/_ssr/fonts/GintoNord/ABCGintoNord-Regular.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNord/ABCGintoNord-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Nord';
  src: url('/_ssr/fonts/GintoNord/ABCGintoNord-Medium.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNord/ABCGintoNord-Medium.woff') format('woff');
  font-style: normal;
  font-weight: medium;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Nord';
  src: url('/_ssr/fonts/GintoNord/ABCGintoNord-Bold.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNord/ABCGintoNord-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Nord';
  src: url('/_ssr/fonts/GintoNord/ABCGintoNord-RegularItalic.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNord/ABCGintoNord-RegularItalic.woff') format('woff');
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Nord';
  src: url('/_ssr/fonts/GintoNord/ABCGintoNord-MediumItalic.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNord/ABCGintoNord-MediumItalic.woff') format('woff');
  font-style: italic;
  font-weight: medium;
  font-display: swap;
}

@font-face {
  font-family: 'Ginto Nord';
  src: url('/_ssr/fonts/GintoNord/ABCGintoNord-BoldItalic.woff2') format('woff2'),
    url('/_ssr/fonts/GintoNord/ABCGintoNord-BoldItalic.woff') format('woff');
  font-style: italic;
  font-weight: bold;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: normal;
  color: #262626;
  font-family: "Ginto Normal", Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #4b01d4;
  transition: all ease-in-out 0.05s;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: #380596;
    text-decoration: underline;
  }

  &:active:not(:disabled) {
    color: #380596;
  }
  &:disabled {
    cursor: default;
  }
}

form {
  margin: 0;
}

th,
td {
  padding: 1rem;
  text-align: left;
}
th:first-child,
td:first-child {
  padding-left: 0;
}
th:last-child,
td:last-child {
  padding-right: 0;
}

hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid #e1e1e1;
}

ul {
  padding: 0;
}

pre {
  line-height: 1.5;
  font-family: inherit;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  overflow: visible;
}
